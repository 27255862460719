jQuery(function($) {

    /*-- Strict mode enabled --*/
    "use strict";

    var _document = $(document),
        _window = $(window),
        _navbarCollapse = $('.navbar-collapse'),
        _navbarToggler = $('.navbar-toggler');

    //animated navbar-toggler button
    _document.on('click', '.navbar .navbar-toggler', function() {
        $(this).toggleClass("change");
    });

    //Close menu when clicked menu-items or outside
    $(".navbar-nav li a").on('click', function() {
        _navbarCollapse.removeClass('show');
        _navbarToggler.removeClass('change');
    });

    _document.on('click', function() {
        if (_navbarCollapse.hasClass('show')) {
            _navbarCollapse.removeClass('show');
            _navbarToggler.removeClass('change');
        }
    });

    //affixed nav with scrollspy
    $('body').scrollspy({
        target: ".navbar",
        offset: 200
    });
    
    //Counter Up js
    $('.counter').counterUp({
        // time: 2000
    });

    // script for box-equal-height
    var maxHeight = 0,
        _equalHeight = function(eq) {
            $(eq).each(function() {
                $(this).find('.equalHeight').each(function() {
                    if ($(this).height() > maxHeight) {
                        maxHeight = $(this).height();
                    }
                });
                $(this).find('.equalHeight').height(maxHeight);
            });
        }
    _equalHeight('.equalHeightWrapper');

    //Change navbar style on scroll
    _window.on('scroll', function() {
        if (_window.scrollTop() >= 100) {
            $('.navbar').addClass('scrolled');
        } else {
            $('.navbar').removeClass('scrolled');
        }
    });

}(jQuery));